@import '../../../size-page.scss';

.cost {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 5vw 0;
  background: #121418;
  overflow: hidden;
  @include for-size(md-down) {
    min-height: 100%;
  }
  &--title {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    margin: 2em 12rem 2rem;
    position: relative;
    @include for-size(md-down) {
      text-align: center;
      align-items: center;
      margin: 4rem 0;
      font-size: 10px;
    }
    & p {
      &:nth-child(1) {
        font-size: 1em;
        text-transform: uppercase;
        color: #757575;
        margin: 0.4em 0.4rem 0.4rem 70px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -70px;
          top: 50%;
          width: 50px;
          height: 1px;
          background: #757575;
          opacity: 0.8;
        }
      }
      &:nth-child(2) {
        font-size: 2.6em;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0.4em 0 0.4em;
      }
    }
  }
  &__container {
    width: 100%;
    height: 100%;
    //overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.5em;
      border: 0 solid;
    }
    &::-webkit-scrollbar-track {
      background: #002c9014;
      border-radius: 1em;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #fdcc75ad;
      outline: 1px solid slategrey;
      border-radius: 1em;
      border: 0 solid #fff;
    }
  }
  &__block {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    display: flex;
    padding-left: 12rem;
    @include for-size(md-down) {
      padding-left: 0;
      flex-direction: column;
    }
    &--container {
      width: 100%;
      height: 100%;
      background-color: #191d27;
      background-image: linear-gradient(160deg, #191d27 0%, #10131a 100%);
      display: flex;
      flex-direction: column;
      color: black;
      transition: .3s;
      @include for-size(md-down) {
        width: 100%;
      }
      &:nth-child(2n) {
        background-color: #191d27;
        background-image: linear-gradient(160deg, #10131a 0%, #10131a 100%);
      }
      &:hover {
        transform: translateY(-10px);
      }
      &__tariff {
        width: 100%;
        //background: linear-gradient(45deg, #283249, #1D62A0);
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;
        &--name {
          text-align: center;
          margin: 3em 1em 1em;
          text-transform: uppercase;
          color: #757575;
          @include for-size(md-down) {
            font-size: 18px;
          }
        }
        &--selection {
          text-align: center;
          //margin: 1em;
          text-transform: uppercase;
          font-size: 1em;
          color: #99b2e4;
          @include for-size(md-down) {
            font-size: 20px;
          }
        }
        &--rate {
          text-align: center;
          margin: 1em;
          text-transform: uppercase;
          padding: 1.2em;
        }
      }
      &__description {
        width: auto;
        padding: 1em 3em;
        height: 200px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 0.5em;
          border: 0 solid;
        }
        &::-webkit-scrollbar-track {
          background: #002c9014;
          border-radius: 1em;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #fdcc75ad;
          outline: 1px solid slategrey;
          border-radius: 1em;
          border: 0 solid #fff;
        }
        &--name {
          color: #484848;
          position: relative;
          margin: 1em 0;
          &:before {
            content: ' ';
            position: absolute;
            left: -20px;
            top: 3px;
            background: #66da58;
            width: 10px;
            height: 10px;
            border-radius: 4px;
          }
        }
      }
      &__info {
        color: #a7a7a7;
        text-align: center;
        padding: 30px 20px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
      }
      &__cost {
        &--price {
          text-align: center;
          //margin: 0.6em 1em 0.4em;
          //padding: 0.6em 0 0 0;
          text-transform: uppercase;
          color: #8bc34a;
          font-size: 2.6vw;
          font-weight: 600;
          @include for-size(md-down) {
            font-size: 34px;
          }
          //border-top: 1px solid #dadada;
          & b {
            font-size: 1vw;
            color: #737373;
            font-weight: 400;
          }
        }
        &--day {
          text-align: center;
          margin: 0 3em 1em;
          color: #737373;
          font-size: 1vw;
          @include for-size(md-down) {
            font-size: 20px;
          }
        }
        &--old {
          text-align: center;
          padding: 1.2rem 0 0 0;
          text-decoration: line-through;
          color: #737373;
          font-size: 1vw;
          @include for-size(md-down) {
            font-size: 20px;
          }
        }
      }
      &__button {
        display: flex;
        justify-content: center;
        margin: 2em 1em 4em 1em;
        & a {
          width: 12vw;
          text-align: center;
          text-decoration: none;
          padding: 0.4em 0;
          height: auto;
          font-size: 1.4em;
          //border: 0 solid;
          border: 1px solid transparent;
          color: white;
          //border-radius: 100px;
          cursor: pointer;
          transition: .3s;
          //background: linear-gradient(48deg, #e08e02, #f9c56c);
          //box-shadow: 0 0 18px #fba30e66;
          overflow: hidden;
          position: relative;
          @include for-size(md-down) {
            font-size: 20px;
            width: auto;
          }
          &:after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: .2;
            position: absolute;
            top: -50px;
            transform: rotate(35deg);
            //transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            width: 50px;
            animation: blicssk 2s infinite cubic-bezier(0.19, 1, 0.22, 1);
            z-index: 1;
          }
          &:hover {
            //transform: scale(1.1);
            border: 1px solid #fff;
            //&:after {
            //  left: 120%;
            //  transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            //}
          }
        }
      }
    }
  }
}

//#h0 {
//  &:after {
//    animation-name: blicssk;
//    animation-delay: 0.5s;
//  }
//}
//#h1 {
//  &:after {
//    animation-name: blicssk;
//    animation-delay: 1s;
//  }
//}
//#h2 {
//  &:after {
//    animation-name: blicssk;
//    animation-delay: 1.5s;
//  }
//}
//#h3 {
//  &:after {
//    animation-name: blicssk;
//    animation-delay: 2s;
//  }
//}

@keyframes blicssk {
  from {
    left: -75px;
  }
  to {
    left: 120%;
  }
}