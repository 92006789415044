@import '../../../size-page.scss';

.about-us {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  //padding: 4vw 0;
  position: relative;
  background: #1a1d23;
  @include for-size(md-down) {
    height: auto;
  }
  &__back {
    &--one{
      position: absolute;
      background: url("../../../assets/images/back-vall-two.svg") 100% 100% no-repeat;
      background-size: cover;
      width: 100%;
      height: 100vh;
      z-index: 2;
      transition: 0.05s;
    }
  }
  &--title {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    & p {
      &:nth-child(1) {
        font-size: 1em;
        text-transform: uppercase;
        color: #757575;
        margin: 0.4em 0.4rem 0.4rem 70px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -70px;
          top: 50%;
          width: 50px;
          height: 1px;
          background: #757575;
          opacity: 0.8;
        }
      }
      &:nth-child(2) {
        font-size: 2.6em;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0.4em 0 0.4em;
        @include for-size(md-down) {
          font-size: 1.6rem;
        }
      }
    }
  }
  &__block {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    display: flex;
    //justify-content: center;
    @include for-size(md-down) {
      flex-direction: column-reverse;
      align-items: center;
    }
    &--text {
      //width: 380px;
      height: 100%;
      text-align: left;
      font-weight: 200;
      font-size: 0.9rem;
      padding: 0 0 0 60px;
      line-height: 1.6;
      color: #c3c3c3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include for-size(md-down) {
        width: 90%;
        height: auto;
        //border-radius: 20px;
        padding: 20px;
        //box-shadow: 0 0 30px #214b7747;
        //background: #282c33;
        font-size: 1rem;
        //margin: 40px;
      }
      & button {
        width: 12vw;
        height: 3.4vw;
        font-size: 1em;
        border: 0 solid;
        color: white;
        border-radius: 100px;
        cursor: pointer;
        transition: .3s;
        background: linear-gradient(48deg, #e08e02, #f9c56c);
        box-shadow: 0 0 18px #fba30e66;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vw 0;
        transition: .3s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    &--img {
      position: relative;
      width: 28rem;
      display: flex;
      align-items: center;
      @include for-size(md-down) {
        height: 18rem;
        width: 18rem;
        margin: 2rem 0 4rem;
      }
      &--full {
        position: relative;
        //background: url("../../../assets/images/escalator-4907329.jpg") -10% 0%;
        background: linear-gradient(#1a1e2345, #1a1e23), url("../../../assets/images/apple-new.jpg") -20% 0%;
        background-size: contain;
        background-attachment: fixed;
        background-repeat: no-repeat;
        width: 60%;
        display: flex;
        align-items: center;
        @include for-size(md-down) {
          background: linear-gradient(#1a1e2345, #131519), url("../../../assets/images/apple-new.jpg") -20% 0%;
          background-size: cover;
          height: 18rem;
          width: 100%;
          margin: 2rem 0 0;
          background-attachment: unset;
        }
      }
      & img {
        position: absolute;
        &:nth-child(2) {
          animation: two-img 1s ease-in-out infinite alternate;
          @keyframes two-img {
            from {
              transform: translateY(10px);
            }
            to {
              transform: translateY(0px);
            }
          }
        }
      }
    }
  }
}
