@import '../../../size-page.scss';

.contact-us {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  //justify-content: center;
  //flex-direction: column;
  align-items: center;
  //padding: 0 0 5vw 0;
  z-index: 5;
  background: #191d23;
  overflow: hidden;
  @include for-size(md-down) {
    flex-direction: column-reverse;
    height: 100%;
    max-height: 100vh;
  }
  &__container {
    width: 100%;
    //height: 100%;
    padding-left: 11rem;
    display: flex;
    align-items: center;
    @include for-size(md-down) {
      padding-left: 0;
      justify-content: center;
      padding-bottom: 4rem;
    }
    &--full {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    &--img {
      position: relative;
      //background: url("../../../assets/images/escalator-4907329.jpg") -10% 0%;
      background: linear-gradient(#1a1e2345, #1a1e23), url("../../../assets/images/pexels-karolina-grabowska-4476630.jpg") 160% 32%;
      background-size: contain;
      background-attachment: fixed;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      @include for-size(md-down) {
        height: 15rem;
        margin: 0rem 0 2rem;
        width: 100%;
        background-attachment: unset;
        background-size: cover;
      }
    }
    & img {
      width: 26rem;
      padding-left: 4rem;
      @include for-size(md-down) {
        display: none;
      }
    }
  }
  &--title {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    //margin: 2em 12rem 2rem;
    position: relative;
    padding-left: 11rem;
    @include for-size(md-down) {
      padding-left: 0;
      align-items: center;
      font-size: 10px;
      text-align: center;
    }
    & p {
      &:nth-child(1) {
        font-size: 1em;
        text-transform: uppercase;
        color: #757575;
        margin: 0.4em 0.4rem 0.4rem 70px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -70px;
          top: 50%;
          width: 50px;
          height: 1px;
          background: #757575;
          opacity: 0.8;
        }
      }
      &:nth-child(2) {
        font-size: 2.2em;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0.4em 0 0.4em;
      }
    }
    &--off {
      display: none;
    }
  }
  &__form {
    //width: 90%;
    //padding: 1em .6em;
    //background: white;
    border-radius: 0.4em;
    display: flex;
    //justify-content: space-evenly;
    //align-items: center;
    //box-shadow: 1em 1em 3em rgba(0, 0, 0, 0.1);
    flex-direction: column;
    width: 68%;
    @include for-size(md-down) {
      width: 70%;
    }
    &--input {
      font-size: 1em;
      padding: 0.6em 1em;
      border: 1px solid transparent;
      //border-radius: 0.4em;
      //margin: 0 0.6em;
      outline: none;
      transition: .3s;
      //background: #eaedf5;
      background: transparent;
      border-bottom: 1px solid #7b7b7b;
      margin: 1rem 0;
      color: white;
      @include for-size(md-down) {
        font-size: 1rem;
      }
      &:hover {
        //background: #dce2f1;
        border-bottom: 1px solid white;
      }
      &--checkbox {
        //margin: 0 0.6em;
        font-size: 0.8em;
        color: white;
        margin: 1rem 0;
        @include for-size(md-down) {
          font-size: 0.6rem;
        }
        & input[type="checkbox"] {
          position: absolute;
          z-index: -1;
          opacity: 0;
          transition: .3s;
          cursor: pointer;
        }
        & label {
          display: inline-flex;
          align-items: center;
          user-select: none;
          transition: .3s;
          cursor: pointer;
          &::before {
            content: '';
            display: inline-block;
            width: 1rem;
            height: 1rem;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #b7b7b7;
            //border-radius: 0.25em;
            margin-right: 1.2em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }
        & input[type="checkbox"]:not(:disabled):not(:checked)+label:hover::before {
          border-color: rgba(179, 215, 255, 0.51);
          background-color: rgba(179, 215, 255, 0.51);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          transition: .3s;
        }
        & input[type="checkbox"]:not(:disabled):active+label::before {
          background-color: #b3d7ff;
          border-color: #b3d7ff;
          transition: .3s;
        }
        & input[type="checkbox"]:focus:not(:checked)+label::before {
          border-color: #b7b7b7;
          transition: .3s;
        }
        & input[type="checkbox"]:checked+label::before {
          border-color: #0b5bb5;
          background-color: #0b5bb5;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          transition: .3s;
        }
      }
      &--button {
        //width: 14em;
        //color: white;
        ///* text-transform: uppercase; */
        //border-radius: 2em;
        //font-size: 1.2em;
        //padding: 0.6em 1.8em;
        //border: 0 solid;
        //margin: 0 0.6em;
        //outline: none;
        //transition: .3s;
        //background: linear-gradient(48deg, #e08e02, #f9c56c);
        //box-shadow: 0 0 18px #fba30e66;
        //cursor: pointer;
        //text-decoration: none;
        border: 1px solid transparent;
        border-bottom: 1px solid;
        padding: 15px 0;
        width: 140px;
        font-size: 1.2rem;
        text-transform: uppercase;
        cursor: pointer;
        transition: .3s;
        background: transparent;
        color: white;
        margin: 0;
        display: flex;
        &:hover {
          border: 1px solid white;
          padding: 15px 10px;
          width: 160px;
        }
        &:disabled {
          //background: linear-gradient(160deg, #8c7854, #e0bf85);
          //box-shadow: 0 0 0 #fff0;
          color: #0b5bb5;
          border: 1px solid #0b5bb5;
          cursor: progress;
          width: 160px;
          display: flex;
          justify-content: center;
        }
        &--success {
          //background: linear-gradient(48deg, #009205, #70c261);
          //box-shadow: 0 0 0 #fff0;
          cursor: progress;
          //transform: scale(1.05);
          pointer-events: none;
          border: 1px solid #009205;
          padding: 15px 10px;
          width: 160px;
          color: #009205;
        }
        &--error {
          //background: linear-gradient(48deg, #c6423c, #f97671);
          //box-shadow: 0 0 0 #fff0;
          color: red;
          border: 1px solid red;
          cursor: no-drop;
          pointer-events: none;
          width: 160px;
        }
        &--loader {
          display: inline-block;
          width: 20px;
          height: 18px;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          transition: .3s;
          position: absolute;
          @keyframes load-new {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          &:after {
            content: " ";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid currentColor;
            border-color: currentColor transparent currentColor transparent;
            animation: load-new 1.2s linear infinite;
          }
          &--yes {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            position: relative;
          }
        }
        &--text {
          display: inline-block;
          width: auto;
          height: 20px;
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          &--no {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            position: absolute;
          }
        }
      }
    }
  }
}
