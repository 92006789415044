@import '../../../size-page.scss';

.preloader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 20;
  background: #121418;
  transition: .3s;
  transform-origin: 0 0;
  transform: translateX(0%);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  color: white;
  @include for-size(md-down) {
    font-size: 2vw;
  }
  &--off {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform-origin: 0 0;
    transform: translateX(0%);
  }
  &--on {
    animation: on .2s linear backwards;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    @keyframes on {
      0% {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
      }
      100% {
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
      }
    }
  }
  & img {
    position: absolute;
    width: 8vw;
    transition: .3s;
    @include for-size(md-down) {
      width: 20vw;
    }
    &:nth-child(1) {
      animation: one .6s linear infinite alternate;
      transform: translateY(10px);
    }
    &:nth-child(2) {
      animation: one .6s linear infinite alternate;
      transform: translateY(-10px);
    }
    @keyframes one {
      from {
        transform: scale(1);
      }
      to {
        transform: scale(1.2);
      }
    }
    @keyframes two {
      from {
        transform: translateX(0px);
      }
      to {
        transform: scale(1.2);
      }
    }
  }
  &--text {
    position: absolute;
    bottom: 0;
    margin-bottom: 4em;
    text-align: center;
  }
}
.parallax > use{
  animation:move-forever 12s linear infinite;
  &:nth-child(1){animation-delay:-2s;}
  &:nth-child(2){animation-delay:-2s; animation-duration:5s}
  &:nth-child(3){animation-delay:-4s; animation-duration:3s}
}

@keyframes move-forever{
  0%{transform: translate(-90px , 0%)}
  100%{transform: translate(85px , 0%)}
}

.vall {
  display: block;
  width: 100%;
  height: 16rem;
  max-height: 100vh;
  margin: 0;
  position: absolute;
  bottom: -20px;
}

/*prevent many large-by-comparison ripples by shrinking the height*/
@media (max-width:50em){
  .content h1{font-size: 12vmax}
  .editorial{height:17vw;}
}
