.privacy-policy {
  min-height: 100vh;
  padding: 10vw 4vw;
  display: flex;
  flex-direction: column;
  &__container {
    width: 100%;
    height: 100%;
  }
  &__block {
    padding: 4vw;
    height: 100%;
    display: flex;
    &__box {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 2em;
        margin: 1em 0;
      }
      &__desc {
        line-height: 2;
        & b {
          padding: 0.2em 0.4em;
          background: #dceeff;
          font-weight: 400;
          border-radius: 0.3rem;
        }
      }
      &--img {
        display: flex;
        align-items: center;
        & img {
          width: 14em;
          filter: invert(99%) sepia(45%) saturate(7109%) hue-rotate(179deg) brightness(89%) contrast(83%);
        }
      }
    }
  }
}
