@import '../../../size-page.scss';

.head {
  width: 100%;
  height: 100vh;
  background: url("../../../assets/images/var-2.svg") 100% 100% no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: 0.05s;
  @include for-size(md-down) {
    height: 30vh;
    background: url("../../../assets/images/var-2.svg") 64% 48% no-repeat;
    background-size: 200% 200%;
  }
  &__back-stars {
    position: absolute;
    background: url("../../../assets/images/back-stars.svg") 100% 100% no-repeat;
    width: 100%;
    height: 100vh;
    z-index: 2;
    transition: 0.05s;
    @include for-size(md-down) {
      height: 30vh;
    }
  }
  &__back-vall {
    position: absolute;
    background: url("../../../assets/images/back-vall.svg") 0 98% no-repeat;
    background-size: cover;
    width: 100%;
    height: 104%;
    z-index: 3;
    transition: 0.05s;
    @include for-size(md-down) {
      height: 102%;
    }
  }
  &__block {
    width: 100%;
    max-width: 80vw;
    color: white;
    z-index: 4;
    position: relative;
    &--title {
      font-size: 2.2vw;
      margin: 10px 0;
      @include for-size(md-down) {
        font-size: 4.2vw;
      }
      &--big {
        font-size: 6.8vw;
      }
    }
    &--button {
      width: 14vw;
      height: 4vw;
      font-size: 1.6em;
      border: 0 solid;
      color: white;
      border-radius: 100px;
      cursor: pointer;
      transition: .3s;
      background: linear-gradient(48deg, #e08e02, #f9c56c);
      box-shadow: 0 0 18px #fba30e66;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2vw 0;
      animation: button-head 1s linear infinite alternate;
      @keyframes button-head {
        from {
          box-shadow: 0 0 18px rgba(251, 163, 14, 0.91);
        }
        to {
          box-shadow: 0 0 18px rgba(251, 37, 148, 0.79);
        }
      }
      @include for-size(md-down) {
        width: 28vw;
        height: 8vw;
        font-size: 3.6em;
        margin: 4vw 0;
      }
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}

.text {
  &--one {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
  }
  &--two {
    opacity: 1;
    visibility: visible;
    pointer-events: visible
  }
}

.text-typing {
  font-weight: 600;
  max-width: 100%;
  b {
    font-weight: 800;
    span {
      color: #000;
    }
  }
  @include for-size(md-down) {
    max-width: 68%;
  }
  &--cursor {
    display: inline-block;
    height: 12vw;
    width: 2px;
    background-color: #fa1c1c;
    margin: -6vw 5px;
    position: relative;
    transform: scale(0.5) translateY(-40%);
    animation: typedjsBlink 0.7s ease infinite;

    &:after {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      //border-radius: 50%;
      position: absolute;
      left: 50%;
      top: 0;
      background-color: #fa1c1c;
      transform: translate3d(-50%, -50%, 0);
    }

    &--change {
      position: relative;
      display: inline-block
    }

    &--stop {
      animation: none;
    }

    &--author {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 999rem;
      height: 20px;
      min-width: 25px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate3d(calc(100% + 5px), -50%, 0);
      color: #fff;
      font-size: 1rem;
      line-height: 1rem;
      padding: 0 8px;
    }
  }
}

@keyframes typedjsBlink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.0;
  }
  100% {
    opacity: 1;
  }
}

.parallax > use{
  &:nth-child(1){
    transform: scale(0.8);
    animation-delay:-2s;
    animation:move-forever 30s linear infinite;
  }
  &:nth-child(2){
    animation-delay:-2s;
    animation-duration:5s;
    animation:move-forever 18s linear infinite;
  }
  &:nth-child(3){
    transform: scale(0.6);
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever-rev 24s linear infinite;
  }
  &:nth-child(4){
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever 20s linear infinite;
  }
  &:nth-child(5){
    transform: scale(0.8);
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever-rev 16s linear infinite;
  }
  &:nth-child(6){
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever 22s linear infinite;
  }
  &:nth-child(7){
    transform: scale(0.6);
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever-rev 10s linear infinite;
  }
  &:nth-child(8){
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever 20s linear infinite;
  }
  &:nth-child(9){
    animation-delay:-4s;
    animation-duration:3s;
    animation:move-forever-rev 16s linear infinite;
  }
}

@keyframes move-forever{
  0%{transform: translate(-50% , 0%)}
  100%{transform: translate(100% , 0%)}
}

@keyframes move-forever-rev{
  0%{transform: translate(100% , 0%)}
  100%{transform: translate(-50% , 0%)}
}

.editorial {
  display: block;
  width: 100%;
  height: 10em;
  max-height: 100vh;
  margin: 0;
  position: absolute;
  bottom: 0;
}

#imgare {
  animation: move-fire-new 2s linear infinite;
}

.fire > svg{
  background: linear-gradient(180deg, #ff0000, #ff6565, #ffb8b8);
  background-size: 600% 600%;
  animation:move-fire 8s linear infinite;
}

@keyframes move-fire{
  0%{background-position:51% 0%}
  50%{background-position:50% 100%}
  100%{background-position:51% 0%}
}
@keyframes move-fire-new{
  0%{transform: translateX(0%)}
  100%{transform: translateX(100%)}
}

.head-new {
  width: 100%;
  height: 100vh;
  //background: #181b25;
  background: #121418;
  @include for-size(md-down) {
    display: none;
  }
  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: url("../../../assets/images/rocksss1.svg") 100% 100%;
    background-size: cover;
    position: relative;
    &--back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 2;
      & img {
        width: 100%;
        height: 100vh;
      }
    }
    &--box {
      padding: 140px 20px 20px 250px;
      color: white;
      z-index: 4;
      &--one {
        font-size: 0.8rem;
        position: relative;
        padding-left: 60px;
        opacity: 0.8;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 50px;
          height: 1px;
          background: white;
          opacity: 0.8;
        }
      }
      &--two {
        font-size: 7rem;
      }
      &--three {
        font-size: 1rem;
        opacity: 0.5;
        padding: 10px 0 60px;
        max-width: 60%;
      }
      &--four {
        border: 1px solid transparent;
        border-bottom: 1px solid;
        padding: 10px 0;
        width: 140px;
        font-size: 1.2rem;
        text-transform: uppercase;
        cursor: pointer;
        transition: .3s;
        &:hover {
          border: 1px solid white;
          padding: 10px;
        }
      }
      &--five {
        display: block;
        font-size: 0.8rem;
        padding: 10px 0;
        text-transform: uppercase;
        cursor: pointer;
        transition: .3s;
        width: 110px;
        border: 1px solid transparent;
        text-decoration: none;
        color: white;
        margin: 0;
        background: transparent;
        text-align: left;
        &:hover {
          border: 1px solid white;
          padding: 10px;
          width: 140px;
        }
      }
    }
  }
}
