.menu {
  visibility: hidden;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  transform: translateY(80px);
  transition: 0.3s;
  &--on {
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0px);
  }
  &--off {
    visibility: hidden;
    pointer-events: none;
    transform: translateY(80px);
  }
  &__block {
    width: 32vw;
    box-shadow: 0 0 40px #2641632b;
    background: #2542669c;
    -webkit-backdrop-filter: saturate(180%) blur(0.4em);
    backdrop-filter: saturate(180%) blur(0.4em);
    display: flex;
    justify-content: space-around;
    padding: 0.5em;
    border-radius: 1em;
    margin: 0 0 2em;
    &--link {
      width: 5em;
      text-align: center;
      padding: 0.5em 0.8em;
      border-radius: 0.6em;
      transition: .3s;
      cursor: pointer;
      text-decoration: none;
      color: #ffffff;
      &:hover {
        background: #00000033;
      }
      &--active {
        background: #00000033;
      }
    }
  }
}
