@import '../../../size-page.scss';

.hr {
  border: 0 solid;
  border-top: 1px solid #c3c3c3;
  width: 15%;
  margin: 0 auto 2em auto;
}

.prices {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 6vw 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  background: #191d23;
  &__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 2rem;
    margin-top: 5em;
    @include for-size(md-down) {
      padding: 0;
    }
    &--container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &--center {
      justify-content: center;
    }
    &__title {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 6em 0 6em;
      &--title {
        width: 100%;
        text-align: center;
        font-size: 2.6em;
        text-transform: uppercase;
        color: #000000;
        margin: 0 0 0.2em;
        background: linear-gradient(45deg, #254d77 33%, #0D61BC 66%, #8AA9D6);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
      &--desc {
        width: 100%;
        text-align: center;
        font-size: 1em;
        color: #7b7b7b;
        margin: 0.2em 0 0;
      }
    }
  }
  &__menu {
    width: 68%;
    display: flex;
    //padding: 14px 0;
    border-radius: 8px;
    background: #353e4a57;
    //margin: 0 1em 0 4em;
    position: relative;
    //margin: 1em 0 1em;
    margin: 0;
    @include for-size(md-down) {
      width: 90%;
      //margin: 20em 0 3em;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    //&:after {
    //  content: '—';
    //  position: absolute;
    //  right: -1.8em;
    //  font-size: 1em;
    //  color: #464646;
    //}
    &--link {
      width: 100%;
      text-align: center;
      padding: 10px;
      margin: 4px;
      transition: .3s;
      background: #f3f6f91f;
      border-radius: 6px;
      text-decoration: none;
      color: #ffffff;
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      @include for-size(md-down) {
        font-size: 0.8rem;
        width: 40%;
      }
      &:hover {
        background: white;
        box-shadow: 0 0 10px #23294821;
        color: #2b2b2b;
      }
      &--active {
        padding: 10px;
        margin: 4px;
        background: white;
        border-radius: 6px;
        box-shadow: 0 0 10px #23294821;
        text-decoration: none;
        color: #2b2b2b;
      }
    }
    &--title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      //margin: 1em 0 1em;
      margin: 0;
      padding-right: 1em;
      color: white;
      &--one {
        font-size: 1em;
        color: white;
        margin: 0 0 0.1em 0;
      }
      &--two {
        font-size: 0.8em;
        color: #7379ff;
        text-decoration-color: #dee0ff;
        //margin: 0 0 0 1.4em;
        &:hover {
          color: #000000;
          text-decoration-color: #c0bcff;
        }
      }
    }
  }
}

