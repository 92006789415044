//$loader-size: 100vw;
//$loader-dot-size: ($loader-size / 5); //20
//$loader-height: $loader-dot-size; //20
//$loader-dot-color: #2196F3;

.list-site-new {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 2vw 0;
  position: relative;
  background: #191e23;
  &--container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__block {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      will-change: transform;
      &--box {
        width: 30%;
        margin: 0 10px;
        height: 50%;
        background: #2f343e;
        animation: nudge1 21s linear infinite;
        @keyframes nudge1 {
          //0% { transform: matrix(1.00,0.00,0.00,1.00,-100,0); }
          //100% { transform: matrix(1.00,0.00,0.00,1.00,100,0); }
          0%{
            transform: translateX(0%);
            opacity: 1;
          }
          100%{
            transform: translateX(calc(100vw + 200%));
            opacity: 1;
          }
        }
      }
      &--box--two {
        width: 30%;
        margin: 0 10px;
        height: 50%;
        background: #2f343e;
        animation: nudge2 16s linear infinite;
        @keyframes nudge2 {
          //0% { transform: matrix(1.00,0.00,0.00,1.00,-100,0); }
          //100% { transform: matrix(1.00,0.00,0.00,1.00,100,0); }
          0%{
            transform: translateX(30%);
            opacity: 1;
          }
          100%{
            transform: translateX(calc(100vw + 200%));
            opacity: 1;
          }
        }
      }
      &--box--three {
        width: 30%;
        margin: 0 10px;
        height: 50%;
        background: #2f343e;
        animation: nudge3 11s linear infinite;
        @keyframes nudge3 {
          //0% { transform: matrix(1.00,0.00,0.00,1.00,-100,0); }
          //100% { transform: matrix(1.00,0.00,0.00,1.00,100,0); }
          0%{
            transform: translateX(60%);
            opacity: 1;
          }
          100%{
            transform: translateX(calc(100vw + 100%));
            opacity: 1;
          }
        }
      }
      @keyframes shift {
        0%{
          transform: translateX(-40%);
          opacity: 1;
        }
        100%{
          transform: translateX(100vw);
          opacity: 1;
        }
      }
    }
    &--slide {
      width: 100%;
      overflow: hidden;
      &--move {
        overflow-x: auto;
        position: relative;
        height: 100%;
        //margin-bottom: 30px;
        display: flex;
        width: 100%;
        //animation: moveOne 100s linear infinite alternate-reverse;
        padding: 35px 0 35px 100px;
        @keyframes moveOne {
          from {
            left: 0;
          }
          to {
            left: 0px;
          }
        }
        &--box {
          position: relative;
          &--text {
            position: absolute;
            bottom: 0;
            left: 35px;
            width: 460px;
            height: 80px;
            display: flex;
            align-items: center;
            font-size: 22px;
            padding-left: 30px;
            z-index: 2;
            color: white;
            text-shadow: 2px 2px 3px black;
          }
        }
        & img {
          margin: 0 35px;
          border-radius: 10px;
          box-shadow: 0 0 30px #00000075;
          height: 100%;
          max-height: 340px;
          width: auto;
          max-width: 610px;
          //box-shadow: 2px 2px 8px #8a8a8a;
        }
        &--two {
          position: relative;
          height: 100%;
          //margin-bottom: 30px;
          display: flex;
          width: 100%;
          animation: moveTwo 100s linear infinite alternate-reverse;
          padding: 35px;
          @keyframes moveTwo {
            from {
              left: -4000px;
            }
            to {
              left: 0;
            }
          }
          &--box {
            position: relative;
            &--text {
              position: absolute;
              bottom: 0;
              left: 35px;
              width: 460px;
              height: 80px;
              display: flex;
              align-items: center;
              font-size: 22px;
              padding-left: 30px;
              z-index: 2;
              color: white;
              text-shadow: 2px 2px 3px black;
              //background: linear-gradient(#e6646500, #191f24);
            }
          }
          & img {
            margin: 0 35px;
            border-radius: 10px;
            box-shadow: 0 0 30px #00000075;
            height: 100%;
            max-height: 264px;
            width: auto;
            max-width: 460px;
            //box-shadow: 2px 2px 8px #8a8a8a;
          }
        }
      }
    }
  }
}

#frame{
  width: 200px;
  height: 200px;

  position: relative;
  left: 300px;
  background-color: lightblue;

}

#carousel{
  position: relative;
  width: 1000px;
  height: 200px;
  background-color: blue;

}

.animate{
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  transition-property: transform;
}

//img{
//  position: relative;
//  float: left;
//  transform: translate(-200px)
//  /*left: 200px;*/
//}

#controls{
  position: relative;
  left: 300px;
  width: 200px;
}

#left{
  float: left;
  width: 33.3%;
}

#index{
  display: inline-block;
  float: left;
  width: 33.3%;
  text-align: center;
}

#right{
  float: right;
  width: 33.3%;
}
