@import '../../../size-page.scss';

.benefits {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  //padding: 4vw 0;
  position: relative;
  background: #121418;
  @include for-size(md-down) {
    height: auto;
  }
  //&__back {
  //  &--one{
  //    position: absolute;
  //    background: url("../../../assets/images/back-vall-two.svg") 100% 100% no-repeat;
  //    background-size: cover;
  //    width: 100%;
  //    height: 100vh;
  //    z-index: 2;
  //    transition: 0.05s;
  //  }
  //}
  &--title {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    & p {
      &:nth-child(1) {
        font-size: 1em;
        text-transform: uppercase;
        color: #757575;
        margin: 0.4em 0.4rem 0.4rem 70px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: -70px;
          top: 50%;
          width: 50px;
          height: 1px;
          background: #757575;
          opacity: 0.8;
        }
      }
      &:nth-child(2) {
        font-size: 2.6em;
        text-transform: uppercase;
        color: #ffffff;
        margin: 0.4em 0 0.4em;
        @include for-size(md-down) {
          font-size: 1.6rem;
        }
      }
    }
  }
  &--contact {
    text-align: right;
    font-size: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    //flex-direction: column;
    //align-items: flex-end;
    &--container {
      width: 100%;
      display: flex;
      flex-direction: column;
      &--block {
        display: flex;
        justify-content: space-between;
        width: 100%;
        @include for-size(md-down) {
          flex-direction: column;
        }
      }
      & h1, h2, h3, h4 {
        font-weight: 200;
        margin: 1.2rem 0;
      }
      & p {
        margin: 1.2rem 0;
        font-size: 0.8rem;
      }
    }
    &--block {
      a {
        transition: .3s;
        &:hover {
          background: #313341;
        }
      }
    }
    &--box {
      display: flex;
      align-items: center;
      padding: 0.5rem;
      margin-bottom: 0.4rem;
      border-radius: 8px;
      background: #252732;
      text-decoration: none;
      outline: none;
      &--img {
        display: flex;
        align-items: center;
        & img {
          width: 1.2rem;
        }
        &--qr {
          width: 9.2vw;
          @include for-size(md-down) {
            width: 100%;
          }
        }
      }
      &--item {
        width: 100%;
        text-align: center;
        padding-right: 0.8rem;
        color: white;
        font-weight: 400;
      }
    }
  }
  &--left {
    background: #191d23;
    .benefits--title {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      & p {
        &:nth-child(1) {
          font-size: 1em;
          text-transform: uppercase;
          color: #757575;
          margin: 0.4em 0.4rem 0.4rem 70px;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: -70px;
            top: 50%;
            width: 50px;
            height: 1px;
            background: #757575;
            opacity: 0.8;
          }
        }
        &:nth-child(2) {
          font-size: 2.6em;
          text-transform: uppercase;
          color: #ffffff;
          margin: 0.4em 0 0.4em;
          @include for-size(md-down) {
            font-size: 1.6rem;
          }
        }
      }
    }
  }
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    &--img {
      width: 32em;
      position: absolute;
      right: -17%;
      &--left {
        position: absolute;
        width: 70%;
        //bottom: 0;
        top: 0;
        left: 0;
        transform: scale(-1, 1);
        z-index: 0;
      }
      &--right {
        position: absolute;
        width: 70%;
        //bottom: 0;
        top: 0;
        right: 0;
        //transform: scale(-1, 1);
        z-index: 0;
      }
      &--bottom {
        //transform: scale(-1, 1);
        position: absolute;
        width: 70%;
        bottom: 0;
        left: 0;
        z-index: 0;
        transform: rotateZ(180deg) scale(-1, 1);
      }
    }
  }
  &__block {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    display: flex;
    //justify-content: center;
    z-index: 1;
    @include for-size(md-down) {
      flex-direction: column;
      align-items: center;
    }
    &--left {
      flex-direction: row-reverse;
      @include for-size(md-down) {
        flex-direction: column;
        align-items: center;
      }
    }
    &--full {
      position: relative;
      //background: url("../../../assets/images/escalator-4907329.jpg") -10% 0%;
      background: linear-gradient(#1a1e2345, #1a1e23), url("../../../assets/images/pexels-polina-zimmerman-3778619.jpg") -70% 62%;
      background-size: cover;
      background-attachment: fixed;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      @include for-size(md-down) {
        height: 18rem;
        width: 100%;
        margin: 2rem 0 0;
        background-attachment: unset;
      }
    }
    &--full--two {
      position: relative;
      //background: url("../../../assets/images/escalator-4907329.jpg") -10% 0%;
      background: linear-gradient(#1a1e2345, #1a1e23), url("../../../assets/images/team.jpg") 12% 15%;
      background-size: 48%;
      background-attachment: fixed;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      @include for-size(md-down) {
        height: 18rem;
        background-size: cover;
        width: 100%;
        background-attachment: unset;
      }
    }
    &--text {
      //width: 38vw;
      width: 65%;
      padding: 0 60px 0 140px;
      height: 100%;
      text-align: right;
      font-weight: 200;
      font-size: 0.9rem;
      line-height: 1.6;
      color: #c3c3c3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      @include for-size(md-down) {
        width: 90%;
        height: auto;
        //border-radius: 20px;
        padding: 0 10px !important;
        //background: #272c33;
        font-size: 1rem;
        //margin: 40px;
      }
      &--left {
        text-align: left;
        padding: 0 60px;
      }
      &--block-left {
        text-align: left;
        align-items: flex-start;
      }
      &--link {
        width: 12vw;
        height: 3.4vw;
        font-size: 1em;
        border: 0 solid;
        color: white;
        border-radius: 100px;
        cursor: pointer;
        transition: .3s;
        background: linear-gradient(48deg, #e08e02, #f9c56c);
        box-shadow: 0 0 18px #fba30e66;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vw 0;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    &--img {
      position: relative;
      width: 40%;
      display: flex;
      align-items: center;
      //margin-left: 4em;
      @include for-size(md-down) {
        height: 18rem;
        width: 100%;
        margin: 2rem 0 0;
      }
      &--left {
        margin-left: 0;
        width: 60%;
        @include for-size(md-down) {
          width: 100%;
        }
        //margin-right: 4em;
      }
      & img {
        position: absolute;
        width: 28rem;
        @include for-size(md-down) {
          height: 18rem;
          width: 18rem;
        }
        &:nth-child(2) {
          animation: two-img 1s ease-in-out infinite alternate;
          @keyframes two-img {
            from {
              transform: translateY(10px);
            }
            to {
              transform: translateY(0px);
            }
          }
        }
      }
    }
  }
}
