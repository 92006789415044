@import '../../../size-page.scss';

$font-size-nav: 1.1vw;

.header {
  width: 100%;
  height: 10vw;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10;
  transition: .3s;
  @include for-size(md-down) {
    display: none;
  }
  &--other {
    height: 10vw;
    background: #191d23;
    //position: fixed;
  }
  &__block {
    width: 100%;
    padding-left: 120px;
    display: flex;
    justify-content: space-between;
    &__logo {
      width: 80%;
      display: flex;
      align-items: center;
      & img {
        width: 10vw;
      }
      :nth-child(2) {
        &:after {
          content: 'скидки';
          position: absolute;
          font-size: 0.5em;
          font-weight: 500;
          text-align: center;
          color: white;
          text-transform: uppercase;
          display: flex;
          justify-content: center;
          align-items: center;
          width: auto;
          height: auto;
          background: #fa1c1c;
          border-radius: 100em;
          top: -0.4rem;
          right: 0.4rem;
          transition: .3s;
          padding: 0.2rem 0.4rem;
        }
      }
    }
    &__nav {
      display: flex;
      justify-content: space-around;
      font-size: $font-size-nav;
      padding-right: 20px;
      &--link {
        color: #bdbdbd;
        text-decoration: none;
        padding: 10px 40px;
        transition: .3s;
        font-weight: 500;
        border-left: 1px solid transparent;
        position: relative;
        display: flex;
        align-items: center;
        //&:hover {
        //  border-radius: 100px;
        //  background: linear-gradient(-100deg, #29a908, #0ccc24);
        //  box-shadow: 0 0 20px #0ccc24b8;
        //}
        &.white {
          color: #fff;
        }
        &:hover {
          color: #fff;
        }
        &--active {
          color: #fa1c1c;
          //&:after {
          //  background: transparent !important;
          //}
        }
      }
    }
  }
}

.header__mobileMenu{
  top: 0;
  position: fixed;
  width: 100%;
  display: block;
  font-size: 3.1vw;
  font-weight: 600;
  color: #000;
  text-decoration: none;
  height: 0;
  z-index: 9;
}

.hamburger-menu {
  height: 0;
}

#menu__toggle {
  opacity: 0;
  box-shadow: none;
  outline: none !important;
  user-select: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  height: 0;
}

#menu__toggle:checked ~ .menu__btn > span {
  animation: menuMobileTopOne 0.5s linear;
  animation-fill-mode: forwards;
}
#menu__toggle:checked ~ .menu__btn > span::before {
  top: 0;
  transform: rotate(0);
}
#menu__toggle:checked ~ .menu__btn > span::after {
  top: 0;
  animation: menuMobileButton 0.5s linear;
  animation-fill-mode: forwards;
}
#menu__toggle:checked ~ .menu__box {
  visibility: visible;
  top: 0;
  opacity: 1;
}
@keyframes menuMobileTopOne {
  0%{transform: rotateX(0deg);}
  50%{transform: rotateX(74deg);}
  100%{transform: rotate(45deg)}
}
@keyframes menuMobileTopTwo {
  0%{transform: rotateX(0deg);}
  50%{transform: rotateX(74deg);}
  100%{transform: rotate(45deg)}
}
@keyframes menuMobileButton {
  0%{transform: rotateX(0deg);}
  50%{transform: rotateX(74deg);}
  100%{transform: rotate(90deg)}
}

@include for-size(md-up) {
  .header__mobileMenu {
    display: none;
  }
}
@include for-size(md-down) {
  .menu__btn {
    display: flex;
    align-items: center;
    position: fixed;
    top: 1.3rem;
    right: 1.3rem;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 1;
    user-select:  none;
    outline: none;
    background: #294049;
    border-radius: 0.4rem;
  }

  @media (max-width: 320px) {
    .menu__btn {
      top: 1.1rem;
      right: 1.3rem;
    }
  }

  .menu__btn > span {
    display: block;
    position: absolute;
    width: 70%;
    left: 15%;
    background-color: #545D6E;
    transition-duration: .25s;
  }
  .menu__btn > span::before,
  .menu__btn > span::after {
    display: block;
    position: absolute;

    width: 100%;
    height: 2px;

    background-color: #fafbff;

    transition-duration: .25s;
  }
  .menu__btn > span::before {
    content: '';
    top: -4px;
  }
  .menu__btn > span::after {
    content: '';
    top: 4px;
  }

  .menu__box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    visibility: hidden;
    /*top: -140%;*/
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0;
    list-style: none;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    color: white;
    /*-webkit-backdrop-filter: saturate(180%) blur(20px);*/
    /*backdrop-filter: saturate(180%) blur(20px);*/
    background-color: #2f343e;
  }

  .menu__box--header {
    width: auto;
    height: auto;
    top: calc(3.5vmin);
    position: absolute;
    line-height: initial;
    font-size: calc(10px + 2vmin);
    padding: calc(4px + 2.2vmin) calc(4px + 8vmin);
    border-radius: calc(1px + 2vmin);
    background: #545D6E;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu__item {
    display: block;
    padding: 12px 24px;
    color: #525252;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition-duration: .2s;
    transition-duration: .2s;
    border-top: 1px solid #e2e2e2;
  }

  .menu__item:hover {
    background-color: #CFD8DC;
  }

  .menu__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
  }

  .menu__container a {
    text-decoration: none;
    color: white;
    padding: 0;
    line-height: normal;
    margin: 1vh 0;
    transition: 0.2s;
  }
}
