@mixin for-size($size) {

  @if $size == xss {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $size == xs-min {
    @media (max-width: 324px) {
      @content;
    }
  } @else if $size == xs {
    @media (max-width: 575px) {
      @content;
    }
  } @else if $size == sm {
    @media (min-width: 576px) and (max-width: 767px) {
      @content;
    }
  } @else if $size == md {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: 992px) {
      @content;
    }
  }
  @else if $size == pc-min {
    @media (min-width: 1200px) and (max-width: 1400px) {
      @content;
    }
  }

  @if $size == sm-up {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $size == md-up {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $size == lg {
    @media (min-width: 992px) {
      @content;
    }
  }
  @if $size == sm-down {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size == md-down {
    @media (max-width: 1024px) {
      @content;
    }
  }
}
