$font-size-nav: 1.1vw;
@import '../../../size-page.scss';

.left-side {
  position: fixed;
  top: 0;
  left: 0;
  width: 120px;
  height: 100%;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: #000000b5;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: .6s;
  @include for-size(md-down) {
    display: none;
  }
  &--open {
    width: 100%;
  }
  &__logo {
    width: 100%;
    height: 9vw;
    display: flex;
    justify-content: center;
    & img {
      width: 40px;
      padding: 10px;
      transition: .3s;
      cursor: pointer;
      &:hover {
        filter: invert(27%) sepia(1%) saturate(124%) hue-rotate(314deg) brightness(83%) contrast(108%);
      }
    }
  }
  &__info {
    color: white;
    display: flex;
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    flex-direction: row;
    transform: rotate(-90deg);
    padding-left: 120px;
    position: relative;
    transition: .6s;
    &:before {
      content: '';
      position: absolute;
      left: 65px;
      top: 50%;
      width: 50px;
      height: 1px;
      background: white;
      opacity: 0.8;
    }
    &--open {
      //opacity: 0;
      //visibility: hidden;
      //pointer-events: none;
      padding-left: 40%;
      transform: rotate(0deg);
      padding-bottom: 20px;
      &:before {
        content: '';
        position: absolute;
        left: 34%;
        top: 22%;
        width: 50px;
        height: 1px;
        background: white;
        opacity: 0.8;
      }
    }
    &--item {
      padding: 0 20px;
      font-size: 0.6rem;
      text-transform: uppercase;
    }
  }
  &--nav {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--link {

    }
  }
  &__block {
    //background: white;
    border-radius: 1em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    //box-shadow: 0 0 30px #225a9012;
    position: relative;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.3s;
    &--open {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
    }
    &__logo {
      & img {
        width: 10vw;
      }
    }
    &--img {
      &--back {
        position: absolute;
        width: 100%;
        top: -9em;
      }
    }
    &__nav {
      width: 50%;
      display: flex;
      justify-content: space-around;
      font-size: $font-size-nav;
      & a {
        color: white;
        text-decoration: none;
        padding: 10px 20px;
        transition: .3s;
        font-weight: 500;
        border-left: 1px solid transparent;
        //&:hover {
        //  border-radius: 100px;
        //  background: linear-gradient(-100deg, #29a908, #0ccc24);
        //  box-shadow: 0 0 20px #0ccc24b8;
        //}
        &:hover {
          border-left: 1px solid #fff;
        }
      }
    }
    &--logo {
      width: 12em;
      display: flex;
      flex-direction: column;
      align-items: center;
      &--img {
        width: 6em;
      }
      &--text {
        text-align: center;
        margin-top: 1em;
        color: #d3d9e1;
      }
    }
    &__menu {
      display: flex;
      flex-direction: column;
      &--men {
        flex-basis: 200px;
        margin-bottom: 30px;
      }
      &--items {
        width: 70%;
        display: flex;
        justify-content: space-evenly;
      }
      &--title {
        font-size: 1.8rem;
        color: white;
        font-weight: 400;
        padding: 0 0 0.4em 0;
        //border-bottom: 1px solid #ffffff59;
      }
      &--item {
        color: white;
        margin: 0.2em 0;
        text-decoration: none;
        transition: .3s;
        font-size: 0.8rem;
        &:hover {
          color: rgba(255, 255, 255, 0.66);
        }
        &:nth-child(2) {
          margin: 0.4em 0 0.2em 0;
        }
      }
    }
  }
}
