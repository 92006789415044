$font-size-nav: 1.1vw;
@import '../../../size-page.scss';

.footer {
  width: 100%;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  z-index: 4;
  flex-direction: column;
  background: #121418;
  overflow: hidden;
  &__block {
    width: 90%;
    height: 100%;
    padding: 1em .6em;
    margin: 5em 0rem 2em 8rem;
    //background: white;
    border-radius: 1em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    //box-shadow: 0 0 30px #225a9012;
    position: relative;
    @include for-size(md-down) {
      margin: 0;
    }
    &__logo {
      & img {
        width: 10vw;
      }
    }
    &--img {
      &--back {
        position: absolute;
        width: 100%;
        top: -9em;
      }
    }
    &__nav {
      width: 50%;
      display: flex;
      justify-content: space-around;
      font-size: $font-size-nav;
      & a {
        color: white;
        text-decoration: none;
        padding: 10px 20px;
        transition: .3s;
        font-weight: 500;
        border-left: 1px solid transparent;
        //&:hover {
        //  border-radius: 100px;
        //  background: linear-gradient(-100deg, #29a908, #0ccc24);
        //  box-shadow: 0 0 20px #0ccc24b8;
        //}
        &:hover {
          border-left: 1px solid #fff;
        }
      }
    }
    &--logo {
      width: 12em;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include for-size(md-down) {
        font-size: 10px;
      }
      &--img {
        width: 6em;
        @include for-size(md-down) {
          width: 6rem;
        }
      }
      &--text {
        text-align: center;
        margin-top: 1em;
        color: #d3d9e1;
        font-size: 0.8em;
      }
    }
    &__menu {
      display: flex;
      flex-direction: column;
      &--items {
        width: 70%;
        display: flex;
        justify-content: space-evenly;
        border-left: 1px solid #ffffff59;
        @include for-size(md-down) {
          display: none;
        }
      }
      &--title {
        font-size: 1.4em;
        color: white;
        font-weight: 400;
        padding: 0 0 0.4em 0;
        //border-bottom: 1px solid #ffffff59;
      }
      &--item {
        color: white;
        margin: 0.2em 0;
        text-decoration: none;
        transition: .3s;
        font-size: 0.8em;
        &:hover {
          color: rgba(255, 255, 255, 0.66);
        }
        &:nth-child(2) {
          margin: 0.4em 0 0.2em 0;
        }
      }
      &--soc {
        display: flex;
        & a {
          transition: .3s;
          &:hover {
            filter: invert(67%) sepia(92%) saturate(1%) hue-rotate(334deg) brightness(92%) contrast(92%);
          }
        }
        & img {
          margin-top: 0.8rem;
          margin-right: 0.8rem;
          width: 1rem;
        }
      }
    }
  }
}
