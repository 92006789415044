.two-block {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  //padding: 4vw 0;
  position: relative;
  &__back {
    &--one{
      position: absolute;
      background: url("../../../assets/images/back-vall-two.svg") 100% 100% no-repeat;
      background-size: cover;
      width: 100%;
      height: 100vh;
      z-index: 2;
      transition: 0.05s;
    }
  }
  &--title {
    width: 100%;
    display: flex;
    & p {
      &:nth-child(1) {
        width: 100%;
        font-size: 1.6em;
        line-height: 1.2;
        text-align: right;
        text-transform: uppercase;
        color: #000000;
        margin: 0.4em 0 0.4em;
        background: linear-gradient(45deg, #254d77 33%, #0D61BC 66%, #8AA9D6);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
      }
      //&:nth-child(2) {
      //  font-size: 1em;
      //  text-transform: uppercase;
      //  color: #757575;
      //  margin: 0.4em 0 0.4em;
      //}
    }
  }
  &__block {
    width: 100%;
    max-width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    &--text {
      width: 38vw;
      height: 100%;
      text-align: right;
      font-weight: 200;
      font-size: 1.5vw;
      line-height: 1.6;
      color: #525252;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      &--link {
        width: 12vw;
        height: 3.4vw;
        font-size: 1em;
        border: 0 solid;
        color: white;
        border-radius: 100px;
        cursor: pointer;
        transition: .3s;
        background: linear-gradient(48deg, #e08e02, #f9c56c);
        box-shadow: 0 0 18px #fba30e66;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 2vw 0;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
    &--img {
      position: relative;
      width: 38vw;
      display: flex;
      align-items: center;
      margin-left: 4em;
      & img {
        position: absolute;
        &:nth-child(2) {
          animation: two-img 1s ease-in-out infinite alternate;
          @keyframes two-img {
            from {
              transform: translateY(10px);
            }
            to {
              transform: translateY(0px);
            }
          }
        }
      }
    }
  }
}
/***********************
 *	Variables
 **********************/
$font-family:             'Open Sans', sans-serif;
$font-size:               1em;

$color-body:              #282828;
$color-text:              #e2e2e2;
$color-link:              #80a1c1;
$color-link-active:       #6386a9;

$variable-skewX:          0deg;
$variable-vHForSkewX:     0vh;
$variable-scrollingTime:  1350ms;

/***********************
 *	Project Main Styles
 **********************/
*,
*:before,
*:after {
  box-sizing: border-box;
  margin:     0;
  padding:    0;
}

body {
  font-family:      $font-family;
  background-color: $color-body;
}

.slider-pages {
  overflow: hidden;
  position: relative;

  height:   100vh;
}

.slider-page {
  position:   absolute;

  top:        0;
  width:      50%;
  height:     100vh;

  transition: transform $variable-scrollingTime;
}

.slider-page--skew {
  overflow:   hidden;
  position:   absolute;

  top:        0;
  width:      140%;
  height:     100%;

  background: $color-body;

  transform:  skewX($variable-skewX * -1);
}

.slider-page--left {
  left:       0;

  transform:  translateX($variable-vHForSkewX * -1)
  translateY(100%)
  translateZ(0);

  .slider-page--skew {
    left:     -40%;
  }

  .slider-page__content {
    padding:          auto 30% auto 30%;

    transform-origin: 100% 0;
  }
}

.slider-page--right {
  left:       50%;

  transform:  translateX($variable-vHForSkewX)
  translateY(-100%)
  translateZ(0);

  .slider-page--skew {
    right:    -40%;
  }

  .slider-page__content {
    padding:          auto 30% auto 30%;

    transform-origin: 0 100%;
  }
}

.slider-page__content {
  position:         absolute;

  display:          flex;
  justify-content:  center;
  align-items:      center;
  flex-flow:        column wrap;

  top:      0;
  left:     0;
  width:    100%;
  height:   100%;
  padding:  0 30% 0 30%;

  color:            $color-text;
  background-size:  cover;

  transform:  skewX($variable-skewX);
  transition: transform $variable-scrollingTime;
}

.slider-page__title {
  margin-bottom: 1em;

  font-size:  $font-size;
  text-align: center;

  text-transform: uppercase;
}

.slider-page__title--big {
  font-size:  $font-size * 1.2;
}

.slider-page__description {
  font-size:  $font-size;
  text-align: center;
}

.slider-page__link {
  color:      $color-link;

  &:hover,
  &:focus {
    color:            $color-link-active;
    text-decoration:  none;
  }
}

/***********************
 *	Project JS Styles
 **********************/
.js-scrolling__page {
  position:   absolute;

  top:        0;
  left:       0;
  width:      100%;
}

.js-scrolling--active {

  .slider-page {
    transform:  translateX(0)
    translateY(0)
    translateZ(0);
  }
}

.js-scrolling--inactive {

  .slider-page__content {
    transform:  skewX($variable-skewX)
    scale(.9);
  }
}

.js-scrolling__page-1 {

  .slider-page--left .slider-page__content {
    background-image: url("https://s-media-cache-ak0.pinimg.com/originals/a6/6a/a3/a66aa3134eb9f439838e676297a75bd6.jpg");
  }

  .slider-page--right .slider-page__content {
    background-color: $color-body;
  }
}

.js-scrolling__page-2 {

  .slider-page--left .slider-page__content {
    background-color: $color-text;
  }
  .slider-page--left .slider-page__title,
  .slider-page--left .slider-page__description {
    color:            $color-body;
  }

  .slider-page--right .slider-page__content {
    background-image: url("https://s-media-cache-ak0.pinimg.com/originals/7a/8d/51/7a8d51f4968960334274ac7959d31a7d.jpg");
  }
}

.js-scrolling__page-3 {

  .slider-page--left .slider-page__content {
    background-image: url("https://s-media-cache-ak0.pinimg.com/originals/4c/d8/7b/4cd87bc93cca92f76285c061cef16585.jpg");
  }

  .slider-page--right .slider-page__content {
    background-color: $color-body;
  }
}
