.how-work {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  //padding: 4vw 0;
  position: relative;
  z-index: 2;
  background: #191e23;
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-align: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    &--steps {
      width: 100%;
      &--svg {
        &--patch {
          fill: none;
          stroke: url(#a);
          stroke-linecap: round;
          stroke-miterlimit: 10;
          stroke-width: 56px;
        }
      }
    }
    &--img {
      width: 32em;
      position: absolute;
      right: -17%;
      &:nth-child(1) {
        animation: space1 8s linear infinite forwards;
      }
      &:nth-child(2) {
        animation: space1 16s linear infinite forwards;
      }
      &:nth-child(3) {
        animation: space1 22s linear infinite forwards;
      }
      @keyframes space1 {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      &--left {
        position: absolute;
        width: 24%;
      }
      &--right {
        position: absolute;
        width: 70%;
        bottom: 0;
        right: 0;
      }
    }
  }
  &__block {
    width: 100%;
    height: 100%;
    z-index: 4;
    &__head {
      margin: 5vw 0 2vw 8vw;
      padding-bottom: 2vw;
      border-bottom: 20px solid #1f2d34;
      /* border-radius: 60%; */
      display: flex;
      justify-content: center;
      & > h1 {
        color: white;
        font-weight: 100;
        display: flex;
        padding-left: 50px;
        & > b {
          //border-bottom: 5px solid #525252;
          font-weight: 200;
          padding-left: 5px;
        }
      }
    }
    &--active {
      background: linear-gradient(360deg, #c0bff0, #9499e2, #9499e2, #526faf, #4d526d) !important;
      background-size: 800% 800% !important;
      box-shadow: 0 0 30px #4d526d;
      -webkit-animation: AnimationName 1.5s;
      -moz-animation: AnimationName 1.5s;
      animation: AnimationName 1.5s !important;

      @-webkit-keyframes AnimationName {
        0%{
          background-position:50% 100%;
          transform: scale(1.06);
        }
        20%{
          transform: scale(0.96);
        }
        40%{
          transform: scale(1.01);
        }
        60%{
          transform: scale(1);
        }
        100%{
          background-position:50% 0%;
          transform: scale(1);
        }
      }
      @-moz-keyframes AnimationName {
        0%{
          background-position:50% 100%;
          transform: scale(1.06);
        }
        20%{
          transform: scale(0.96);
        }
        40%{
          transform: scale(1.01);
        }
        60%{
          transform: scale(1);
        }
        100%{
          background-position:50% 0%;
          transform: scale(1);
        }
      }
      @keyframes AnimationName {
        0%{
          background-position:50% 100%;
          transform: scale(1.06);
        }
        20%{
          transform: scale(0.96);
        }
        40%{
          transform: scale(1.01);
        }
        60%{
          transform: scale(1);
        }
        100%{
          background-position:50% 0%;
          transform: scale(1);
        }
      }
    }
    &__ul {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0vw 5vw 2vw 16vw;
      position: relative;
      &--li {
        width: 100%;
        display: flex;
        align-items: center;
        &:nth-child(2n) {
          .how-work__block__ul--li--box {
            border-radius: 2em 2em 2em 2em;
          }
        }
        &--box {
          width: 30vw;
          height: 12vw;
          position: relative;
          display: flex;
          border-radius: 2em 2em 2em 2em;
          transition: 2s;
          background: linear-gradient(360deg, #526faf, #4d526d, #29333c, #1c2931);
          background-size: 800% 800%;

          -webkit-animation: AnimationName2 2s;
          -moz-animation: AnimationName2 2s;
          animation: AnimationName2 2s;

          @-webkit-keyframes AnimationName2 {
            0%{background-position:50% 100%}
            100%{background-position:50% 0%}
          }
          @-moz-keyframes AnimationName2 {
            0%{background-position:50% 100%}
            100%{background-position:50% 0%}
          }
          @keyframes AnimationName2 {
            0%{background-position:50% 100%}
            100%{background-position:50% 0%}
          }
          &--num {
            display: flex;
            align-items: center;
            &--name {
              width: 2.8em;
              height: 1.8em;
              margin: 0.5em;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.4em;
              font-size: 2.6em;
              //background: #1d5192;
              color: #ffffff;
              & img {
                width: 1.4em;
              }
            }
          }
          &--info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0.4em 2em 0.4em 0;
            &--title {
              font-size: 2em;
              padding: 0 0 0.4em 0;
              color: #ffffff;
            }
            &--desc {
              color: #ffffff;
              font-size: 14px;
            }
          }
        }
        &:not(:nth-child(2n)) {
          justify-content: flex-start;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 50%;
            height: 136%;
            width: 2em;
            background: #202c34;
          }
          &:after {
            content: '';
            position: absolute;
            right: 50%;
            height: 1em;
            width: 8em;
            background: #202c34;
            border-top-left-radius: 0.4em;
            border-bottom-left-radius: 0.4em;
          }
          //&:before {
          //  content: '';
          //  display: block;
          //  width: 1em;
          //  height: 5.6em;
          //  border-radius: 1em;
          //  background: #dae4f1;
          //  position: absolute;
          //  top: -55%;
          //  left: 14%;
          //}
          //&:after {
          //  content: '';
          //  display: block;
          //  width: 58%;
          //  height: 2em;
          //  border-radius: 1em;
          //  background: #dae4f1;
          //}
        }
        &:nth-child(2n) {
          justify-content: flex-end;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            left: 50%;
            height: 100%;
            width: 2em;
            background: #202c34;
          }
          &:after {
            content: '';
            position: absolute;
            left: 50%;
            height: 1em;
            width: 8em;
            background: #202c34;
            border-top-right-radius: 0.4em;
            border-bottom-right-radius: 0.4em;
          }
          //&:before {
          //  content: '';
          //  display: block;
          //  width: 58%;
          //  height: 1em;
          //  border-radius: 1em;
          //  background: #dae4f1;
          //}
          //&:after {
          //  content: '';
          //  display: block;
          //  width: 1em;
          //  height: 5.6em;
          //  border-radius: 1em;
          //  background: #dae4f1;
          //  position: absolute;
          //  top: -55%;
          //  right: 14%;
          //}
        }
      }
    }
  }
}
