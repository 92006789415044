
.services {
  width: 100%;
  height: 60vh;
  position: relative;
  //display: flex;
  //align-items: center;
  //&--img {
  //  position: absolute;
  //  width: 100%;
  //  height: 100%;
  //  background: url("../../../assets/images/top-back-vall.svg") 100% 100%;
  //  background-size: cover;
  //  top: -10vw;
  //}
  &__back {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    text-align: center;
    display: flex;
    align-items: center;
    overflow: hidden;
    &--img {
      width: 32em;
      position: absolute;
      right: -17%;
      &--left {
        position: absolute;
        width: 24%;
      }
      &--right {
        position: absolute;
        width: 70%;
        bottom: 0;
        left: 0;
        transform: scale(-1, 1);
      }
    }
  }
  &__block {
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    flex-wrap: wrap;
    z-index: 2;
    &__box {
      width: 28vw;
      //height: 10vw;
      //padding: 1em;
      perspective: 30px;
      &--back {
        //width: 100%;
        height: 100%;
        display: flex;
        //flex-direction: column;
        justify-content: center;
        padding: 2em 3em;
        //margin: 1em;
        //border-radius: 2em;
        color: white;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        cursor: default;
        font-size: 0.8em;
        &--one {
          background-color: #D9AFD9;
          background-image: linear-gradient(0deg, #D9AFD9 0%, #97D9E1 100%);
          animation: animBlocks 1.1s linear infinite;
          //transition: box-shadow 1s;
          //&:hover {
          //  box-shadow: 0 0 18px #97D9E1db;
          //  transform: scale(1.02);
          //}
        }
        &--two {
          background-color: #8BC6EC;
          background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
          animation: animBlocks 1.3s linear infinite forwards;
          //transition: box-shadow 1s;
          //&:hover {
          //  box-shadow: 0 0 18px #9599E2db;
          //}
        }
        &--three {
          background-color: #FBAB7E;
          background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
          animation: animBlocks 1.1s linear infinite forwards;
          //transition: box-shadow 1s;
          //&:hover {
          //  box-shadow: 0 0 18px #F7CE68db;
          //}
        }
        &--four {
          background-color: #FBAB7E;
          background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
          animation: animBlocks 1.3s linear infinite forwards;
          //transition: box-shadow 1s;
          //&:hover {
          //  box-shadow: 0 0 18px #FF99ACdb;
          //}
        }
        &--five {
          background-color: #FF9A8B;
          background-image: linear-gradient(90deg, #FF9A8B 0%, #FF99AC 100%);
          animation: animBlocks 1.1s linear infinite forwards;
          //transition: box-shadow 1s;
          //&:hover {
          //  box-shadow: 0 0 18px #FF99ACdb;
          //}
        }
        &--six {
          background-color: #8BC6EC;
          background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
          animation: animBlocks 1s linear infinite forwards;
          //transition: box-shadow 1s;
          //&:hover {
          //  box-shadow: 0 0 18px #FF99ACdb;
          //}
        }
        //@keyframes animBlocks {
        //  0% {
        //    transform: scale(1);
        //  }
        //  50% {
        //    box-shadow: 0 0 18px #97D9E1db;
        //    transform: scale(1.02);
        //  }
        //  100% {
        //    transform: scale(1);
        //  }
        //}
      }
      &--img {
        width: 6em;
        margin-right: 2em;
      }
      &--tab {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &--title {
        font-size: 2em;
        margin-bottom: 0.5em;
      }
      &--desc {
        font-size: 1.2em;
      }
    }
  }
}
