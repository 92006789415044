@import '../../../size-page.scss';

.promo {
  width: 100%;
  height: 40vh;
  background: #191d23;
  position: relative;
  @include for-size(md-down) {
    height: auto;
  }
  &__container {
    padding: 2em 4rem 2rem 12rem;
    height: 100%;
    display: flex;
    @include for-size(md-down) {
      flex-direction: column;
      padding: 1em;
    }
    &--title {
      color: white;
      font-weight: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 50px;
      width: 20%;
      @include for-size(md-down) {
        width: auto;
        margin: 0;
        font-size: 16px;
        text-align: center;
        padding: 10px 0;
      }
      &--accent {
        //background: linear-gradient(390deg,#ffffff,#fff, #131519);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        border-bottom: 5px solid #525252;
      }
    }
    &--block {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include for-size(md-down) {
        width: 95%;
      }
      & > div {
        display: flex;
        justify-content: space-between;
        @include for-size(md-down) {
          flex-direction: column;
        }
      }
      &--box {
        color: white;
        display: flex;
        align-items: center;
        flex: 1;
        height: 70px;
        padding: 10px;
        border: 1px solid #525252;
        border-radius: 8px;
        margin: 10px;
        background: #191f24;
        & > img {
          width: 34px;
        }
        & > p {
          width: 100%;
          text-align: center;
          @include for-size(md-down) {
            font-size: 14px;
          }
        }
      }
    }
  }
}