@import '../../../size-page.scss';

.promo2 {
  width: 100%;
  height: auto;
  background: #121418;
  position: relative;
  &__container {
    padding: 6em 4rem 6rem 12rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    @include for-size(md-down) {
      padding: 1em;
    }
    &--title {
      color: white;
      font-weight: 100;
      display: flex;
      margin-bottom: 50px;
      @include for-size(md-down) {
        margin: 10px 0;
        font-size: 18px;
        justify-content: center;
      }
      &--accent {
        //background: linear-gradient(390deg,#ffffff,#fff, #131519);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;
        border-bottom: 5px solid #525252;
      }
    }
    &--block {
      //width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      & > div {
        display: flex;
        justify-content: space-between;
        @include for-size(md-down) {
          flex-direction: column;
        }
      }
      &--box {
        color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        //height: 70px;
        padding: 10px;
        //border: 1px solid #525252;
        border-radius: 8px;
        margin: 10px;
        background: #191f24;
        & > img {
          width: 5em;
          margin: 30px 0;
          @include for-size(md-down) {
            width: 60px;
          }
        }
        & > p {
          width: 100%;
          text-align: center;
          &:nth-child(2) {
            font-size: 1.4rem;
            margin-bottom: 20px;
          }
          &:nth-child(3) {
            margin-bottom: 20px;
            color: #869198;
            @include for-size(md-down) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}