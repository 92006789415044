@import '../../../size-page.scss';

.send-email {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000094;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: .3s;
  transform: translateY(10px);
  &--on {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    transform: translateY(0);
  }
  &__container {
    width: 60%;
    height: auto;
    background: #191d23;
    //border-radius: 0.2rem;
    padding: 2rem;
    z-index: 101;
    position: relative;
    @include for-size(md-down) {
      width: 90%;
    }
    &--close {
      position: absolute;
      right: 0;
      top: 0;
      margin: 0.8rem 0.8rem;
      padding: 0.4rem 0.8rem;
      //background: #ececec;
      background: #373f4c;
      color: white;
      border-radius: 0.4rem;
      transition: .3s;
      cursor: pointer;
      @include for-size(md-down) {
        font-size: 0.8rem;
        padding: 0.2rem 0.6rem;
      }
      &:hover {
        //background: #c9c9c9;
        background: #535c6d;
      }
    }
    &--title {
      text-align: center;
      font-size: 1rem;
      padding: 1rem;
      color: white;
      @include for-size(md-down) {
        padding: 0;
      }
    }
    &--form {
      display: flex;
      flex-direction: column;
      &--checkbox {
        margin: 0 0.6em;
        font-size: 0.8em;
        display: flex;
        @include for-size(md-down) {
          font-size: 0.6rem;
          flex-direction: column;
        }
        & input[type="radio"] {
          position: absolute;
          z-index: -1;
          opacity: 0;
          transition: .3s;
        }
        & label {
          display: flex;
          flex: 1;
          align-items: center;
          user-select: none;
          transition: .3s;
          background: transparent;
          color: white;
          padding: 0.8rem 0.6rem;
          //border-radius: 0.4rem;
          margin: 0.4em 1em 0 0;
          font-size: 1.2em;
          border: 1px solid transparent;
          cursor: pointer;
          //&:hover {
          //  border: 1px solid rgba(21, 118, 239, 0.51);
          //}
          &::before {
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            flex-shrink: 0;
            flex-grow: 0;
            border: 1px solid #adb5bd;
            border-radius: 0.25em;
            margin-right: 0.6em;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 50% 50%;
          }
        }
        & input[type="radio"]:not(:disabled):not(:checked)+label:hover::before {
          border-color: rgba(179, 215, 255, 0.51);
          background-color: rgba(179, 215, 255, 0.51);
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          transition: .3s;
        }
        & input[type="radio"]:not(:disabled):active+label::before {
          background-color: #b3d7ff;
          border-color: #b3d7ff;
          transition: .3s;
        }
        & input[type="radio"]:focus:not(:checked)+label::before {
          border-color: #b7b7b7;
          transition: .3s;
        }
        & input[type="radio"]:checked+label::before {
          border-color: #0b5bb5;
          background-color: #0b5bb5;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
          transition: .3s;
        }
        & input[type="radio"]:checked+label {
          border: 1px solid #1576ef;
        }
      }
      &--input {
        margin: 0 0.6em;
        font-size: 0.8em;
        display: flex;
        @include for-size(md-down) {
          flex-direction: column;
        }
        & input {
          display: flex;
          flex: 1 1;
          align-items: center;
          transition: .3s;
          //background: #f6f3ff;
          //padding: 0.6rem;
          //border-radius: 0.4rem;
          //margin: 1em 1em 0 0;
          font-size: 1.2em;
          border: 1px solid transparent;
          text-decoration: none;
          outline: none;
          padding: 0.6em 1em;
          //border: 1px solid transparent;
          background: transparent;
          border-bottom: 1px solid #7b7b7b;
          margin: 1rem 0;
          color: white;
          @include for-size(md-down) {
            font-size: .8rem;
          }
          &:hover {
            //background: #dce2f1;
            border-bottom: 1px solid white;
          }
        }
      }
      &--text {
        margin: 0 0.6em;
        font-size: 0.8em;
        display: flex;
        & textarea {
          display: flex;
          flex: 1 1;
          align-items: center;
          transition: .3s;
          //background: #f6f3ff;
          padding: 0.8rem;
          //border-radius: 0.4rem;
          //margin: 1em 1em 0 0;
          font-size: 1.2em;
          background: transparent;
          //border: 1px solid transparent;
          border: 1px solid transparent;
          border-bottom: 1px solid #7b7b7b;
          text-decoration: none;
          outline: none;
          color: white;
          &:hover {
            //background: #dce2f1;
            border-bottom: 1px solid white;
          }
          @include for-size(md-down) {
            font-size: .8rem;
          }
        }
      }
      &--button {
        //width: 14em;
        //color: white;
        ///* text-transform: uppercase; */
        //border-radius: 2em;
        //font-size: 1.2em;
        //padding: 0.6em 1.8em;
        //border: 0 solid;
        //margin: 0 0.6em;
        //outline: none;
        //transition: .3s;
        //background: linear-gradient(48deg, #e08e02, #f9c56c);
        //box-shadow: 0 0 18px #fba30e66;
        //cursor: pointer;
        //text-decoration: none;
        border: 1px solid transparent;
        border-bottom: 1px solid;
        padding: 15px 0;
        width: 140px;
        font-size: 1.2rem;
        text-transform: uppercase;
        cursor: pointer;
        transition: .3s;
        background: transparent;
        color: white;
        margin: 0;
        text-align: center;
        &:hover {
          border: 1px solid white;
          //padding: 15px 10px;
          width: 100%;
        }
        &--block {
          display: flex;
          justify-content: center;
        }
        &:disabled {
          //background: linear-gradient(160deg, #8c7854, #e0bf85);
          //box-shadow: 0 0 0 #fff0;
          color: #0b5bb5;
          border: 1px solid #0b5bb5;
          cursor: progress;
          width: 160px;
          display: flex;
          justify-content: center;
        }
        &--success {
          //background: linear-gradient(48deg, #009205, #70c261);
          //box-shadow: 0 0 0 #fff0;
          cursor: progress;
          //transform: scale(1.05);
          pointer-events: none;
          border: 1px solid #009205;
          padding: 15px 10px;
          width: 160px;
          color: #009205;
        }
        &--error {
          //background: linear-gradient(48deg, #c6423c, #f97671);
          //box-shadow: 0 0 0 #fff0;
          color: red;
          border: 1px solid red;
          cursor: no-drop;
          pointer-events: none;
          width: 160px;
        }
        &--loader {
          display: inline-block;
          width: 20px;
          height: 18px;
          opacity: 0;
          visibility: hidden;
          pointer-events: none;
          transition: .3s;
          position: absolute;
          @keyframes load-new {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          &:after {
            content: " ";
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 2px solid currentColor;
            border-color: currentColor transparent currentColor transparent;
            animation: load-new 1.2s linear infinite;
          }
          &--yes {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            position: relative;
          }
        }
        &--text {
          display: inline-block;
          width: auto;
          height: 20px;
          opacity: 1;
          visibility: visible;
          pointer-events: auto;
          &--no {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            position: absolute;
          }
        }
      }
    }
  }
}
